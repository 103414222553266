.formula-editor-wrapper .marker.hover-contents {
    display: none;
}

/* https://stackoverflow.com/questions/69303942/dont-let-flex-to-wrap-label-text-of-toggle */
label,
input,
button {
    width: 130px;
}
