.redirectPageContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .row {
        padding-top: 30px;
        text-align: center;

        button {
            margin-left: 20px !important;

            width: auto;
            padding-left:20px;
            padding-right:20px;
        }
    }

    

    .row p {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
        
    }

}