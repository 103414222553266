.App {
    width: 100%;
}

.editor-wrapper {
    height: 450px;
}

.editor-wrapper {
    border: solid 1px #e4e4e4;
}

.docs-demoGrid .block{
    line-height: 50px;

    height: 50px;
    margin-bottom: 20px;

    text-align: center;

    color: #fff;
    background-color: #a6a6a6;
}

.verticalspace {
    height: 290px;
    /* height: 20px */
 }
 
 /* On screens that are 996px or less */
 @media screen and (max-width: 996px) {
   .verticalspace {
     height: 20px;
   }
 }

/* header {
    width: 80%;
    margin: 0 auto;
    display: flex;
}

header .logo {
    width: 200px;
    height: auto;
    background: red;
}

header p {
    padding: 0;
    margin: 0;
}

header h1 {
    padding: 0;
    margin: 0;
}

header .title {
    padding: 20px;
}

.clear {
    clear: both;
}


footer {
    padding-top: 50px;
}

footer .copy-right {
    text-align: center;
}

footer span {
    color: #007bff;
}

.info {
    padding-top: 20px;
    border-top: solid 1px #e4e4e4;
}

.info>p {
    padding: 0;
    margin: 0;
}

.info ul {
    display: flex;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
}

.info ul a {
    color: #007bff;
    text-decoration: none;
}

.hr {
    width: 90%;
    display: none;
    border: none;
    height: 1px;
    color: #c0c0c0;
    background-color: #c0c0c0;
} */