@import '../../../node_modules/office-ui-fabric-core/src/sass/References.scss';

.addinItem {
  padding-top: 10px;
}

.titleText {
  color: $ms-color-themePrimary;
}

.button {
  width: 100%;
  margin: 0px auto 0px auto;
}

.container {
  padding: 0px 10px;
}
