.hr {
    width: 90%;
    display: none;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: #c0c0c0; /* old IE */
    background-color: #c0c0c0; /* Modern Browsers */
}

.unparsable-error-message{
    line-height: 0;
    color: #DA5145;
}